ul[data-v-3506654e] {
  display: block;
  background-color: white;
  padding: 3px;
  margin: 2px 4px 2px 4px;
  width: 36px;
  height: 36px;
}
.colors li[data-v-3506654e] {
  display: block;
  margin: 0;
  padding: 0;
  float: left;
  height: 30px;
}
.selected[data-v-3506654e] {
  border: 1px solid black;
  padding: 2px;
}
