@media only screen and (min-width: 600px) {
#cart_small_container[data-v-2caab295] {
    position: absolute;
    top: 30px;
    width: 600px;
    right: 10px;
    margin: 4px 0 4px 4px;
    background-color: white;
    z-index: 3000;
}
}
#cart_small_container[data-v-2caab295] {
  position: absolute;
  top: 30px;
  margin: 4px 0 4px 4px;
  background-color: white;
  z-index: 3000;
}
