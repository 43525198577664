.jwrap {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.jwrap div {
  width: 100% !important;
  height: 100% !important;
}
.jwrap .green {
  background-color: #6eda6e !important;
  opacity: 0.5;
}
.jwrap .red {
  background-color: #ee4e4e !important;
}
.jwrap .gray {
  background-color: gray;
  opacity: 0.5;
}
