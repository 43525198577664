#gyms_autocomplete_container {
  position: relative;
}
#gyms_autocomplete_container .loading_box {
  border: 1px solid gray;
  border-radius: 2px;
  width: 100%;
  padding: 4px 8px 4px 8px;
}
@media only screen and (min-width: 900px) {
#gyms_autocomplete_container .ga_suggestions {
    width: 600px;
}
}
#gyms_autocomplete_container .ss-suggestion:hover {
  background-color: #4aae9b;
  color: white;
}
#gyms_autocomplete_container .ss-suggestion .tr_link {
  display: flex;
  flex-direction: row;
}
#gyms_autocomplete_container .ss-suggestion .sr_label {
  margin: 2px 4px 2px 4px;
  color: black;
}
#gyms_autocomplete_container .ss-suggestion .sr_id {
  width: 70px;
  margin: 2px 4px 2px 4px;
  color: black;
  font-weight: bold;
}
