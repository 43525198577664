.jwrap {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.jwrap div {
  width: 100% !important;
  height: 100% !important;
}
.jwrap .green {
  background-color: #6eff6e !important;
  opacity: 0.3;
}
.jwrap .red {
  background-color: #ee4e4e !important;
  opacity: 0.8;
}
.jwrap .grayOdd {
  background-color: #e6e6e6;
  opacity: 0.5;
}
.jwrap .grayEven {
  background-color: #d6d6d6;
  opacity: 0.5;
}
