ul[data-v-b9c25478] {
  display: block;
  background-color: white;
  padding: 5px;
  margin: 2px 4px 2px 4px;
}
.colors li[data-v-b9c25478] {
  display: block;
  float: left;
  height: 30px;
}
