#filters li[data-v-81942ffe] {
  list-style-type: none;
  text-transform: uppercase;
  color: "#f4f4f4";
  font-size: 12px;
}
#filters input[type=checkbox][data-v-81942ffe] {
  float: left;
  display: block;
  margin-right: 8px;
}
